import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import Api from './../../api/Api';
import { QUERYKEYS } from './../../api/ApiConstants';
import { LinearProgress, Button, Typography, Stack } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, useParams } from 'react-router-dom';

const DEBUG = true;

const CreatedFile = ({ file }) => {
    if (!file) {
        return null;
    }

    return <>
        <Typography variant="body2">
            <FormattedMessage
                id="prefilledProtocol.generate.success" />: <a href={file.url} target="_blank">
                {file.name}
            </a>
        </Typography>
    </>
}

const DocumentGeneration = () => {
    const msalInstance = useMsal().instance;
    const { projectId } = useParams();
    const [error, setError] = useState(null);
    const [file, setFile] = useState(null);

    const mutation = useMutation({
        mutationFn: Api.postGenerateDocument({ msalInstance }),
        onSuccess: (result) => {
            if (DEBUG) console.log("DocumentGeneration :: postGenerateDocument :: onSuccess")
            if (DEBUG) console.log(result);
            setFile(result.result.file);
            setError(null);
        },
        onError: (error) => {
            setError(error);
        }
    });

    return <Stack spacing={4}>
        <Typography variant="h4">
            <FormattedMessage id="prefilledProtocol.generate.header" />
        </Typography>
        {error && <Typography variant="body2">
            <FormattedMessage id="prefilledProtocol.generate.error" />
        </Typography>}
        {file && <div
            style={{
                opacity: mutation.isPending ? 0.5 : 1,
            }}>
            <CreatedFile file={file} />
        </div>}
        <div>
            {(mutation.isPending) && <LinearProgress />}
            <Button
                disabled={mutation.isPending}
                onClick={() => mutation.mutate({
                    queryKey: [
                        Math.random(),
                        {
                            projectId
                        }
                    ]
                })}>{
                    mutation.isPending ?
                        <FormattedMessage id="prefilledProtocol.generate.progress" /> :
                        <FormattedMessage id="prefilledProtocol.generate.button" />
                }
            </Button>
        </div>
    </Stack>
}

export default DocumentGeneration;