// @TEMPLATE: Define the routes here.
const RoutePaths = {
    root: '/',
    docai: {
        root: '/doc-ai',
        projects: {
            root: '/doc-ai/projects',
        },
        protocol: {
            root: '/doc-ai/pre-filled-protocol',
            project: '/doc-ai/pre-filled-protocol/:projectId',
        },
    },
}

export default RoutePaths;