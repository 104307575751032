import React, { useEffect, useState } from 'react';
import { Container, Box, Stack, Typography, Skeleton, CircularProgress } from '@mui/material';
import ChatMessages from './ChatMessages';
import ChatInput from './ChatInput';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppState, Actions } from './../../AppState';
import Api from './../../api/Api';
import { createEndpointUrl, ENDPOINTS } from '../../api/ApiConstants';

import RoutePaths from '../../routes/RoutePaths';
import { useMsal } from '@azure/msal-react';
import ChatSessions from './ChatSessions';
import { FormattedMessage, useIntl } from 'react-intl';

const DEBUG = true;

const ProjectChat = () => {
    const intl = useIntl();
    const queryClient = useQueryClient();
    const [text, setText] = useState('');
    const [selectedSessionId, setSelectedSessionId] = useState(null);
    const [newSession, setNewSession] = useState(false);
    const { projectId } = useParams();
    const { state, dispatch } = useAppState();
    const msalInstance = useMsal().instance;
    const sessionsQueryKey = projectId ? createEndpointUrl(ENDPOINTS.getChatSessions, { projectId }) : ""
    const sessionQueryKey = (selectedSessionId && projectId) ? createEndpointUrl(ENDPOINTS.getChatSession, { sessionId: selectedSessionId, projectId }) : ""
    const inputPlaceholder = selectedSessionId ?
        intl.formatMessage({ id: "prefilledProtocol.chat.placeholder" }) :
        intl.formatMessage({ id: "prefilledProtocol.chat.new" });

    // Queries for
    // - All Sessions
    // - All Messages for a Session

    // const { isLoading, error, data } = useQuery({
    const sessionsQuery = useQuery({
        queryKey: [
            // QueryKey goes here. See ApiConstants.js for more info.
            sessionsQueryKey,
            // Parameters go here.
            {
                projectId
            }],
        // Query function to make the actual query with.
        queryFn: Api.getChatSessions({ msalInstance }),
        // To disable the query, use this.
        enabled: projectId != null && projectId.length > 0,
        // Make sure this option makes sense to your context.
        refetchOnWindowFocus: false,
    });

    const sessionQuery = useQuery({
        queryKey: [
            // QueryKey goes here. See ApiConstants.js for more info.
            sessionQueryKey,
            // Parameters go here.
            {
                sessionId: selectedSessionId,
                projectId,
            }],
        // Query function to make the actual query with.
        queryFn: Api.getChatSession({ msalInstance }),
        // To disable the query, use this.
        enabled: selectedSessionId != null && projectId != null && projectId.length > 0,
        // Make sure this option makes sense to your context.
        refetchOnWindowFocus: false,
    });

    // Mutations for
    // - Create a new Session
    // - Create a new Message

    const postSessionMutation = useMutation({
        mutationFn: Api.postChatSession({ msalInstance }),
        onError: (error, newData, context) => {
            if (DEBUG) console.log("ProjectChat :: postSessionMutation :: ERROR")
            if (DEBUG) console.log(error);
            // TODO: HANDLE ERROR
        },
        onSuccess: (result) => {
            if (DEBUG) console.log("ProjectChat :: postSessionMutation :: onSuccess")
            if (DEBUG) console.log(result);
            queryClient.invalidateQueries([sessionsQuery, { projectId }]);

            if (result.result) {
                setSelectedSessionId(
                    result.result.id);

                setNewSession(true);

            }
        }
    });

    useEffect(() => {
        if (newSession && text && text.length > 0) {
            send(text);
            setNewSession(false);
        }
    }, [selectedSessionId]);


    const postMessageMutation = useMutation({
        mutationFn: Api.postChatMessage({ msalInstance }),
        // Optimistic update
        onMutate: async (mutationData) => {
            if (DEBUG) console.log("ProjectChat :: postMessageMutation :: onMutate");
            if (DEBUG) console.log(mutationData);

            const [_key, { sessionId, messageContent }] = mutationData.queryKey;

            // Cancel pending queries to prevent sync issues
            await queryClient.cancelQueries([
                sessionQueryKey, {
                    sessionId,
                    projectId,
                }]);

            if (DEBUG) console.log("sessionQueryKey", [
                sessionQueryKey, {
                    sessionId,
                    projectId,
                }]);

            let previousData = queryClient
                .getQueryData([
                    sessionQueryKey, {
                        sessionId,
                        projectId,
                    }]);
            if (DEBUG) console.log("ProjectChat :: postMessageMutation :: onMutate :: previousData")
            if (DEBUG) console.log(previousData);

            if (!previousData) {
                previousData = {
                    id: sessionId,
                    result: {
                        chat_messages: []
                    }
                }
            };

            let updatedSession = {
                ...previousData.result,
                chat_messages: [
                    ...previousData.result.chat_messages,
                    {
                        message_content: messageContent,
                        role: 'user',
                        created: Date.now()
                    }
                ]
            };

            if (DEBUG) console.log("ProjectChat :: postMessageMutation :: onMutate :: updatedSession");
            if (DEBUG) console.log(updatedSession);

            queryClient.setQueryData([
                sessionQueryKey, {
                    sessionId,
                    projectId,
                }], {
                queryKey: [
                    sessionQueryKey, {
                        sessionId,
                        projectId,
                    }],
                result: updatedSession
            });

            return { previousData, messageContent };
        },
        // If the mutation fails, roll back to the previous data
        onError: (error, newData, context) => {
            if (DEBUG) console.log("ProjectChat :: postMessageMutation :: ERROR")
            if (DEBUG) console.log(error);
            console.log("context", context.previousData);
            setText(context.messageContent);
            queryClient.setQueryData(
                [
                    sessionQueryKey, {
                        sessionId: selectedSessionId,
                        projectId,
                    }], context.previousData);
        },
        // After the mutation succeeds, invalidate the query to re-fetch the data
        onSettled: () => {
            queryClient.invalidateQueries([
                sessionQueryKey, {
                    sessionId: selectedSessionId,
                    projectId,
                }]);
        },
        onSuccess: (result) => {
            if (DEBUG) console.log("ProjectChat :: postMessageMutation :: onSuccess")
            if (DEBUG) console.log(result);

            let previousData = queryClient.getQueryData([
                sessionQueryKey, {
                    sessionId: selectedSessionId,
                    projectId,
                }]);
            if (DEBUG) console.log("ProjectChat :: postMessageMutation :: onSuccess :: previousData")
            if (DEBUG) console.log(previousData);

            if (!previousData) {
                // TODO: BETTER ERROR HANDLING
                return;
            };

            let updatedSession = {
                ...previousData.result,
                chat_messages: [
                    ...previousData.result.chat_messages,
                    result.result
                ]
            };

            if (DEBUG) console.log("ProjectChat :: postMessageMutation :: onSuccess :: updatedSession");
            if (DEBUG) console.log(updatedSession);

            queryClient.setQueryData([
                sessionQueryKey, {
                    sessionId: selectedSessionId,
                    projectId,
                }], {
                queryKey: [
                    sessionQueryKey, {
                        sessionId: selectedSessionId,
                        projectId,
                    }],
                result: updatedSession
            });
        }
    });

    const send = (message, session = null) => {
        //setMessages([...messages, { created: Date.now(), message_content: message, role: 'user' }]);

        if (!selectedSessionId && !session) {
            postSessionMutation.mutate({
                queryKey: [
                    Math.random(),
                    {
                        projectId
                    }
                ]
            });
            return;
        } else {
            setText('');
            postMessageMutation.mutate({
                queryKey: [
                    Math.random(),
                    {
                        sessionId: session ? session : selectedSessionId,
                        messageContent: message
                    }
                ]
            });
        }
    };

    //if (DEBUG) console.log("ProjectChat :: sessionsQuery", sessionsQuery.data);
    //if (DEBUG) console.log("ProjectChat :: sessionQuery", sessionQuery.data);
    //if (DEBUG) console.log("ProjectChat :: selectedSessionId", selectedSessionId);

    return (
        <Stack direction="column" justifyContent="space-between" height="100%">
            <Box overflow="auto">
                {
                    selectedSessionId === null &&
                    <Typography variant="h4">
                        <FormattedMessage
                            id="prefilledProtocol.chat.previous" />
                    </Typography>
                }
                {
                    selectedSessionId === null && sessionsQuery.data &&
                    <ChatSessions
                        setSelectedSessionId={setSelectedSessionId}
                        sessions={sessionsQuery.data.result} />
                }
                {
                    selectedSessionId !== null && sessionQuery.data &&
                    <ChatMessages
                        messages={
                            sessionQuery.data.result.chat_messages
                        } />
                }
                {
                    sessionsQuery.isLoading && !selectedSessionId &&
                    <>
                        <Skeleton variant="rectangular" width="100%" height={44} style={{ marginTop: "14px", marginBottom: "14px" }} />
                        <Skeleton variant="rectangular" width="100%" height={44} style={{ marginTop: "14px", marginBottom: "14px" }} />
                        <Skeleton variant="rectangular" width="100%" height={44} style={{ marginTop: "14px", marginBottom: "14px" }} />
                    </>
                }
                {
                    sessionQuery.isLoading && selectedSessionId &&
                    <>
                        <Skeleton variant="rectangular" width="100%" height={44} style={{ marginTop: "14px", marginBottom: "14px" }} />
                        <Skeleton variant="rectangular" width="100%" height={44} style={{ marginTop: "14px", marginBottom: "14px" }} />
                        <Skeleton variant="rectangular" width="100%" height={44} style={{ marginTop: "14px", marginBottom: "14px" }} />
                    </>
                }
            </Box>
            <ChatInput
                disabled={
                    postMessageMutation.isPending ||
                    postSessionMutation.isPending}
                text={text}
                setText={setText}
                placeholder={
                    inputPlaceholder
                }
                send={send}
                sending={postMessageMutation.isPending}
            />
        </Stack>
    );
};

export default ProjectChat;