import React from 'react';
import { useAppState, Actions } from './AppState';
import { Stack, Box, Button, Menu, MenuItem } from "@mui/material";
import Navi from './routes/Navi';
import { FormattedMessage } from 'react-intl';

const LanguageMenu = () => {
    const { state, dispatch } = useAppState();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLocaleChange = (locale) => {
        dispatch({
            type: Actions.setLocale,
            payload: locale
        });
    };

    return (
        <div>
            <Button
                size="small"
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {state.locale}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleLocaleChange("fi");
                    handleClose();
                }}>FI</MenuItem>
                <MenuItem onClick={() => {
                    handleLocaleChange("en");
                    handleClose();
                }}>EN</MenuItem>
                <MenuItem onClick={() => {
                    handleLocaleChange("sv");
                    handleClose();
                }}>SV</MenuItem>
                <MenuItem onClick={() => {
                    handleLocaleChange("de");
                    handleClose();
                }}>DE</MenuItem>
            </Menu>
        </div>
    );
}

const ExampleRoot = () => {
    return <Box style={{
        backgroundColor: "white",
        height: "105px",
        boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
    }}>
        <Stack
            direction="row"
            justifyContent="space-between">
            <img
                src="/parmaco-logo.png"
                alt="Parmaco"
                style={{
                    width: "200px",
                    height: "50px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    paddingLeft: "30px",
                }} />
            <Stack>
                <Stack direction="column"
                    alignItems="end">
                    <LanguageMenu />
                    <h2 style={{
                        textAlign: "right",
                        marginRight: "26px",
                        marginTop: "0px",
                        marginBottom: "10px",
                        color: '#0693e3'
                    }}>
                        <FormattedMessage
                            id="app.name" />
                    </h2>
                </Stack>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    pb={1}
                    pr={"26px"}
                >
                    <Navi />
                </Box>
            </Stack>
        </Stack>
    </Box >;
}

export default ExampleRoot;