import React, { useEffect, useRef } from 'react';
import { List, ListItem, ListItemText, Box } from '@mui/material';
//import { makeStyles } from '@mui/styles';

//const useStyles = makeStyles((theme) => ({
const styles = {
    ownMessage: {
        backgroundColor: '#dfdfdf',
        textAlign: 'right',
        borderRadius: '10px',
        padding: '10px',
        width: '80%'
    },
    systemMessage: {
        backgroundColor: '#ffffff',
        textAlign: 'left',
        borderRadius: '10px',
        padding: '10px',
        width: '80%'
    },
};

const ChatMessages = ({ messages }) => {
    //const classes = useStyles();
    const messagesEndRef = useRef(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(scrollToBottom, [messages]);

    if (!messages) {
        console.log("ChatMessages :: messages is null");
        return null;
    }

    return (
        <List>
            {messages
                .sort((a, b) => a.created - b.created)
                .map((message, index) => (
                    <div
                        key={index}
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: message.role === 'user' ? 'flex-end' : 'flex-start'
                        }}>
                        <ListItem style={{ maxWidth: "80%" }}>
                            <ListItemText
                                primary={message.message_content}
                                style={
                                    message.role === 'user'
                                        ? styles.ownMessage
                                        : styles.systemMessage
                                }
                            />
                        </ListItem>
                    </div>
                ))}
            <div ref={messagesEndRef} />
        </List>
    );
};

export default ChatMessages;