import React, { createContext, useContext, useReducer } from 'react';
import { browserLocale, locales } from './messages/messages';

const DEBUG = true;

export const Actions = {
    setLocale: 'setLocale',
    setActiveProject: 'setActiveProject',
    // @TEMPLATE: Add more actions here.
}

const AppState = createContext();

const initialState = {
    locale: browserLocale,
    // @TEMPLATE: Add more initial state here.
};

const reducer = (state, action) => {
    if (DEBUG) console.log("AppState.js :: reducer :: action.type :: ", action.type);
    if (DEBUG) console.log("AppState.js :: reducer :: action.payload :: ", action.payload);
    switch (action.type) {
        case Actions.setLocale:
            if (action.payload === null ||
                locales[action.payload] === undefined) {
                if (DEBUG) console.log("AppState.js :: reducer :: ERROR :: Invalid locale");

                return state;
            }

            return {
                ...state,
                locale: action.payload,
            };
        case Actions.setActiveProject:
            if (action.payload === null) {
                if (DEBUG) console.log("AppState.js :: reducer :: ERROR :: Invalid project");

                return state;
            }

            return {
                ...state,
                selectedProject: action.payload,
            };
        // @TEMPLATE: Add more cases here.
        default:
            if (DEBUG) console.log("AppState.js :: reducer :: ERROR :: Unknown action type");
            return state;
    }
};

const AppStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <AppState.Provider value={{ state, dispatch }}>
            {children}
        </AppState.Provider>
    );
};

const useAppState = () => {
    return useContext(AppState);
};

export { AppStateProvider, useAppState };