import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const Citation = ({ text, author }) => {
    const theme = useTheme();  // Access the current theme

    return (
        <Box
            sx={{
                borderLeft: `4px solid ${theme.palette.primary.main}`,  // Use the primary color from the theme
                paddingLeft: 2,
                marginBottom: 2,
            }}
        >
            <Typography
                variant="body1"
                sx={{
                    fontStyle: 'italic',
                    fontSize: '1.1rem',
                    color: theme.palette.text.primary,  // Optionally use theme text color
                }}
            >
                “{text}”
            </Typography>
            {author && (
                <Typography
                    variant="body2"
                    sx={{
                        textAlign: 'right',
                        marginTop: 1,
                        fontSize: '0.9rem',
                        color: theme.palette.text.secondary,  // Optionally use theme secondary text color
                    }}
                >
                    {author}
                </Typography>
            )}
        </Box>
    );
};

export default Citation;