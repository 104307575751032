export const ENDPOINTPATHS = {
    // @TEMPLATE - Define the API endpoints here.
    exampleGet: '/api/exampleGet',
    examplePost: '/api/examplePost',

    projects: 'projects',
    analysis: 'analysis', //analysis suffix
    chat: 'chat',
    sessions: 'sessions',
    messages: 'messages',
    documents: 'documents',
    generate: 'generate',
}

export const ENDPOINTS = {
    getProject: "getProject",
    getProjects: "getProjects",
    putProject: "putProject",
    getProjectAnalysis: "getProjectAnalysis",
    postProjectAnalysis: "postProjectAnalysis",
    putProjectAnalysisAnswer: "putProjectAnalysisAnswer",
    getChatSessions: "getChatSessions",
    postChatSession: "postChatSession",
    getChatSession: "getChatSession",
    postChatMessage: "postChatMessage",
    postGenerateDocument: "postGenerateDocument",
}

export const ANSWER_STATUS = {
    processing: "processing",
    success: "success",
    error: "error",
}

export const PROJECT_STATUS = {
    indexing: "indexing",
    analysing: "analysing",
    error: "error",
    new: "new",
    success: "success",
}

export const createEndpointUrl = function (endpoint, params) {
    switch (endpoint) {
        case ENDPOINTS.getProject:
        case ENDPOINTS.getProjects:
            return ENDPOINTPATHS.projects;
        case ENDPOINTS.putProject:
            if (!params || !params.projectId) {
                throw new Error("projectId is required for putProject");
            }
            return `${ENDPOINTPATHS.projects}/${params.projectId}`;
        case ENDPOINTS.getProjectAnalysis:
            if (!params || !params.projectId) {
                throw new Error("projectId is required for getProjectAnalysis");
            }
            return `${ENDPOINTPATHS.projects}/${params.projectId}/${ENDPOINTPATHS.analysis}`;
        case ENDPOINTS.postProjectAnalysis:
            if (!params || !params.projectId) {
                throw new Error("projectId is required for postProjectAnalysis");
            }
            return `${ENDPOINTPATHS.projects}/${params.projectId}/${ENDPOINTPATHS.analysis}`;
        case ENDPOINTS.putProjectAnalysisAnswer:
            if (!params || !params.projectId || !params.answerId) {
                throw new Error("projectId and answerId are required for putProjectAnalysisAnswer");
            }
            return `${ENDPOINTPATHS.projects}/${params.projectId}/${ENDPOINTPATHS.analysis}/${params.answerId}`;
        case ENDPOINTS.getChatSessions:
            if (!params || !params.projectId) {
                throw new Error("projectId is required for getChatSessions");
            }
            return `${ENDPOINTPATHS.projects}/${ENDPOINTPATHS.chat}/${ENDPOINTPATHS.sessions}/${params.projectId}`;
        case ENDPOINTS.postChatSession:
            if (!params || !params.projectId) {
                throw new Error("projectId is required for postChatSession");
            }
            return `${ENDPOINTPATHS.projects}/${ENDPOINTPATHS.chat}/${ENDPOINTPATHS.sessions}/${params.projectId}`;
        case ENDPOINTS.getChatSession:
            if (!params || !params.sessionId) {
                throw new Error("sessionId is required for putProjectAnalysisAnswer");
            }
            if (!params || !params.projectId) {
                throw new Error("projectId is required for postChatSession");
            }
            return `${ENDPOINTPATHS.projects}/${ENDPOINTPATHS.chat}/${ENDPOINTPATHS.sessions}/${params.projectId}/${params.sessionId}`;
        case ENDPOINTS.postChatMessage:
            return `${ENDPOINTPATHS.projects}/${ENDPOINTPATHS.chat}/${ENDPOINTPATHS.messages}`;
        case ENDPOINTS.postGenerateDocument:
            if (!params || !params.projectId) {
                throw new Error("projectId is required for postGenerateDocument");
            }
            return `${ENDPOINTPATHS.projects}/${ENDPOINTPATHS.documents}/${ENDPOINTPATHS.generate}/${params.projectId}`;
        default:
            throw new Error(`Unknown endpoint: ${endpoint}`);
    }
}