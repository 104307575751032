import React from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from './../../api/Api';
import { useAppState, Actions } from '../../AppState';
import { ENDPOINTS, PROJECT_STATUS, createEndpointUrl } from './../../api/ApiConstants';
import {
    CircularProgress,
    Box,
    ListItem,
    List,
    ListItemButton,
    ListItemIcon,
    Typography,
    Stack,
    Paper,
    InputBase,
    IconButton,
    LinearProgress
} from '@mui/material';
import { Link } from 'react-router-dom';
import RoutePaths from '../../routes/RoutePaths';
import { useMsal } from '@azure/msal-react';
import FolderIcon from '@mui/icons-material/Folder';
import SearchIcon from '@mui/icons-material/Search';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useIntl } from 'react-intl';
import ImportProjectDialog from './ImportProjectDialog';

let DEBUG = true;

const Project = ({
    project,
    setImportDialogOpen,
    setProjectToImport }) => {
    const { state, dispatch } = useAppState();
    const intl = useIntl();

    const formattedDate = intl.formatDate(
        project.created_date, { year: 'numeric', month: 'short', day: 'numeric' });
    const formattedTime = intl.formatTime(
        project.created_date, { hour: 'numeric', minute: 'numeric' });

    return <ListItem >
        <Link
            underline="none"
            to={
                project.imported ?
                    `${RoutePaths.docai.protocol.root}/${project.id}` :
                    null}
            key={project.id}
            onClick={
                project.imported ?
                    () => {
                        dispatch({
                            type: Actions.setActiveProject,
                            payload: project
                        });
                    } : (project.status !== PROJECT_STATUS.indexing && project.status !== PROJECT_STATUS.analysing) ?
                        () => {
                            setProjectToImport(project);
                            setImportDialogOpen(true);
                        } : null
            }
            style={{
                color: "#163073",
                textDecoration: "none",
                width: "100%"
            }}
        >
            <ListItemButton>
                <ListItemIcon>
                    <Stack>
                        {(!project.imported && (project.status === PROJECT_STATUS.new || project.status === PROJECT_STATUS.success)) && <CreateNewFolderOutlinedIcon />}
                        {(project.imported && (project.status === PROJECT_STATUS.new || project.status === PROJECT_STATUS.success)) && <FolderIcon />}
                        {project.status === PROJECT_STATUS.indexing && <UploadFileOutlinedIcon />}
                        {project.status === PROJECT_STATUS.analysing && <FindInPageOutlinedIcon />}
                        {project.status === PROJECT_STATUS.error && <ErrorOutlineOutlinedIcon />}
                        {(project.status === PROJECT_STATUS.analysing || project.status === PROJECT_STATUS.indexing) && <LinearProgress />}
                    </Stack>
                </ListItemIcon>
                <Stack
                    spacing={1}
                    style={{ width: "100%" }}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={1}>
                        <Typography variant="h6">
                            {project.name}
                        </Typography>
                        <Typography
                            variant="body1"
                            pl={4}
                            style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                            {formattedDate}
                        </Typography>
                    </Stack>
                    {
                        project.description && <Typography
                            variant="body1"
                            style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                            {project.description}
                        </Typography>
                    }
                </Stack>
            </ListItemButton>
        </Link>
    </ListItem>
}

const ProjectFilter = ({ text, setText }) => {
    const intl = useIntl();
    const placeholder = intl.formatMessage({ id: "project.search.placeholder" });

    return <Paper
        component="form"
        sx={{
            p: '2px 4px',
            display: 'flex',
            alignItems: 'center',
            minWidth: '400px',
            width: '100%',
        }}
    >
        <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder={placeholder}
            value={text}
            onChange={(e) => setText(e.target.value)}
        />
        <IconButton
            type="button"
            sx={{ p: '10px' }}
            aria-label="search">
            <SearchIcon />
        </IconButton>
    </Paper>;
}

const ProjectPage = () => {
    const msalInstance = useMsal().instance;
    const queryClient = useQueryClient();
    const [text, setText] = React.useState("");
    const [importDialogOpen, setImportDialogOpen] = React.useState(false);
    const [importProjectDescription, setImportProjectDescription] = React.useState("");
    const [projectToImport, setProjectToImport] = React.useState(null);

    const { isLoading, error, data } = useQuery({
        queryKey: [
            // QueryKey goes here. See ApiConstants.js for more info.
            createEndpointUrl(ENDPOINTS.getProjects),
        ],
        // Query function to make the actual query with.
        queryFn: Api.getProjects({ msalInstance }),
        // To disable the query, use this.
        enabled: true,
        // Make sure this option makes sense to your context.
        refetchOnWindowFocus: false,
    });

    const projectAnalysisMutation = useMutation({
        mutationFn: Api.postProjectAnalysis({ msalInstance }),
        onSuccess: (result) => {
            if (DEBUG) console.log("ProjectPage :: projectAnalysisMutation :: onSuccess")
            if (DEBUG) console.log(result);

            queryClient
                .invalidateQueries([
                    createEndpointUrl(ENDPOINTS.getProjects)]);
        },
        onError: (error) => {
            // TODO: Handle error
            //setError("Pysyvää tietokenttää tallennettaessa tapahtui palvelinvirhe. ");
        }
    });

    const projectMutation = useMutation({
        mutationFn: Api.putProject({ msalInstance }),
        onSuccess: (result) => {
            if (DEBUG) console.log("ProjectPage :: projectMutation :: onSuccess")
            if (DEBUG) console.log(result);

            projectAnalysisMutation.mutate({
                queryKey: [
                    Math.random(),
                    {
                        projectId: projectToImport.id,
                    }
                ]
            });

            queryClient
                .invalidateQueries([
                    createEndpointUrl(ENDPOINTS.getProjects)]);
            setImportProjectDescription("");
        },
        onError: (error) => {
            // TODO: Handle error
            //setError("Pysyvää tietokenttää tallennettaessa tapahtui palvelinvirhe. ");
        }
    });

    let projectToImportId = projectToImport ? projectToImport.id : null;

    return <>
        <ImportProjectDialog
            open={importDialogOpen}
            projectId={projectToImportId}
            description={importProjectDescription}
            setDescription={setImportProjectDescription}
            handleAccept={() => {
                setImportDialogOpen(false);
                projectMutation.mutate({
                    queryKey: [
                        Math.random(),
                        {
                            projectId: projectToImportId,
                            updatedProject: {
                                ...projectToImport,
                                description: importProjectDescription,
                                imported: true
                            }
                        }
                    ]
                });
            }}
            handleDecline={() => {
                setImportDialogOpen(false);
                setImportProjectDescription("");
            }} />
        {
            isLoading && <div style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <CircularProgress />
            </div>
        }
        <Box style={{
            marginRight: "auto",
            marginLeft: "auto",
            width: "90%",
            height: "100%",
            justifyContent: "center",
            display: "flex",
        }}>
            {error && <div>Error: {error.message}</div>}
            {data &&
                <Stack spacing={1}>
                    <ProjectFilter
                        text={text}
                        setText={setText} />
                    <List
                        sx={{
                            width: '100%',
                            //maxWidth: 360,
                            bgcolor: 'background.paper',
                            overflow: 'auto',
                        }}
                    >
                        {
                            data
                                .result
                                .sort((a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime())
                                .filter((project) => {
                                    if (text === "") return true;
                                    return project.name.toLowerCase().includes(text.toLowerCase()) ||
                                        (project.description && project.description.toLowerCase().includes(text.toLowerCase()));
                                })
                                .map((project, index) => {
                                    return <Project
                                        project={project}
                                        setImportDialogOpen={setImportDialogOpen}
                                        setProjectToImport={setProjectToImport}
                                        key={project.id} />
                                })
                        }
                    </List>
                </Stack>
            }
        </Box>
    </>
}

export default ProjectPage;