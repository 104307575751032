import { Box } from '@mui/material';
import AppHeader from './AppHeader';
import { ThemeProvider } from '@mui/material';
import ParmacoTheme from './ParmacoTheme';

const AppPage = ({ children }) => {
    return <ThemeProvider theme={ParmacoTheme.light}>
        <div style={{
            height: "100%",
            overflow: 'auto',
        }}>
            <AppHeader />
            <Box
                maxWidth="xl"
                style={{
                    marginRight: "auto",
                    marginLeft: "auto",
                    paddingTop: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: "calc(100% - 135px)"
                }}>
                {children}
            </Box>
        </div>
    </ThemeProvider>
}

export default AppPage;