import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppState, Actions } from './../../AppState';
import Api from './../../api/Api';
import { createEndpointUrl, ENDPOINTS, ANSWER_STATUS } from '../../api/ApiConstants';
import { FormattedDisplayName, FormattedMessage } from 'react-intl';
import {
    Box,
    Typography,
    Stack,
    Button,
    CircularProgress,
    LinearProgress
} from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Citation from './Citation';
import RoutePaths from '../../routes/RoutePaths';
import { useMsal } from '@azure/msal-react';
import ProjectChat from '../chat/ProjectChat';
import DocumentGeneration from './DocumentGeneration';

var DEBUG = true;

const TAB_CONSTANTS = {
    chat: "chat",
    generate: "generate",
}

const styles = {
    root: {
        height: '100%',
        display: 'flex',
    },
    tabs: {
        minWidth: '200px',
        //backgroundColor: theme.palette.background.paper,
        //borderRight: `1px solid ${theme.palette.divider}`,
        position: 'sticky',
        top: 0,
        maxHeight: '100%',
        height: '100%',
    },
    chatContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100% - 80px)',
        overflowY: 'auto',
        padding: '40px'
    },
    chatContent: {
        flex: 1,
        overflowY: 'auto',
        //padding: theme.spacing(2),
    },
};

function ensureSingleDotAtEnd(input) {
    // Remove all trailing dots
    let trimmed = input.replace(/\.*$/, '');
    // Add a single dot at the end
    return trimmed + '.';
}

const AnswerAnalysisIcon = ({ answer }) => {
    if (!answer || !answer.status) {
        return null;
    }

    if (answer.status === ANSWER_STATUS.success) {
        if (answer.accepted === true) {
            return <span>✔️</span>;
        } else if (answer.accepted === false) {
            return <span>❌</span>;
        } else {
            return null;
        }
    } else if (answer.status === ANSWER_STATUS.error) {
        return <span>❗</span>;
    } else if (answer.status === ANSWER_STATUS.processing) {
        return <CircularProgress
            thickness={8}
            size="1rem" />;
    }
}

const TabPanel = ({
    projectAnalysis,
    selectedTab,
    setSelectedTab,
    resetChat }) => {
    if (!projectAnalysis || projectAnalysis.length === 0) {
        // TODO: BETTER ERROR HANDLING
        return <div>No projects</div>
    } else {
        const tabStyle = {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            textAlign: 'left'
        };
        let tabs = projectAnalysis.map((analysis, index) => {
            return <Tab
                sx={tabStyle}
                label={<>
                    <AnswerAnalysisIcon answer={analysis.answer} />&nbsp;
                    <span>{analysis.question.name}</span>
                </>}
                key={analysis.question.id} />
        });

        tabs.push(<Tab
            sx={tabStyle}
            label={<div
                onClick={resetChat}>
                <FormattedMessage
                    id="prefilledProtocol.analysis.tabs.chat" />
            </div>}
            key={"ask"} />);

        tabs.push(<Tab
            sx={tabStyle}
            label={<div>
                <FormattedMessage
                    id="prefilledProtocol.analysis.tabs.generate" />
            </div>}
            key={"generate"} />);

        return <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={(event, newValue) => {
                setSelectedTab(newValue)
            }}
            sx={{ alignItems: 'flex-start' }}
        >{tabs}</Tabs>
    }
}

const AnalysisAnswer = ({ projectAnalysisQuestion }) => {
    return (<>
        <Typography variant="body1">
            {
                (projectAnalysisQuestion.answer.text && projectAnalysisQuestion.answer.text.length) > 0 &&
                <>
                    {
                        ensureSingleDotAtEnd(
                            projectAnalysisQuestion.answer.text)
                    }<br /><br />
                </>
            }
            {
                ensureSingleDotAtEnd(
                    projectAnalysisQuestion.answer.reasoning)
            }
        </Typography>
    </>);
}

const AnalysisEvidence = ({ projectAnalysisQuestion }) => {
    if (!projectAnalysisQuestion || !projectAnalysisQuestion.answer || !projectAnalysisQuestion.answer.evidence || projectAnalysisQuestion.answer.evidence.length === 0)
        return null;

    const evidenceComponents = projectAnalysisQuestion.answer.evidence.map((evidence, index) => {
        return <Citation
            text={evidence.excerpt}
            key={index}
            author={
                <>
                    {evidence.filename} <FormattedMessage id="prefilledProtocol.analysis.evidence.page" /> {evidence.page}
                </>}
        />;

    });

    return <>
        <Typography variant="h5">
            <FormattedMessage id="prefilledProtocol.analysis.evidence.header" />
        </Typography>
        {evidenceComponents}
    </>
}

const AnalysisConflicts = ({ projectAnalysisQuestion }) => {
    return <>
        <Typography variant="h5">
            <FormattedMessage id="prefilledProtocol.analysis.conflicts.header" />
        </Typography>
        <Typography variant="body1">
            {
                (!projectAnalysisQuestion.answer || !projectAnalysisQuestion.answer.conflicts || projectAnalysisQuestion.answer.conflicts === "") ?
                    <FormattedMessage id="prefilledProtocol.analysis.conflicts.na" />
                    : projectAnalysisQuestion.answer.conflicts
            }
        </Typography>
    </>
}

const PrefilledProtocol = () => {
    const queryClient = useQueryClient();
    const { projectId } = useParams();
    const { state, dispatch } = useAppState();
    const navigate = useNavigate();
    const selectedProject = state.selectedProject;
    const [selectedTab, setSelectedTab] = useState(0);
    const [resetChat, setResetChat] = useState(0);
    const msalInstance = useMsal().instance;
    const queryKey = projectId ? createEndpointUrl(ENDPOINTS.getProjectAnalysis, { projectId }) : ""

    const { isLoading, error, data } = useQuery({
        queryKey: [
            // QueryKey goes here. See ApiConstants.js for more info.
            queryKey,
            // Parameters go here.
            {
                projectId
            }],
        // Query function to make the actual query with.
        queryFn: Api.getProjectAnalysis({ msalInstance }),
        // To disable the query, use this.
        enabled: projectId != null && projectId.length > 0,
        // Make sure this option makes sense to your context.
        refetchOnWindowFocus: false,
    });

    const answerStatusMutation = useMutation({
        mutationFn: Api.putProjectAnalysisAnswer({ msalInstance }),
        // Optimistic update
        onMutate: async (mutationData) => {
            if (DEBUG) console.log("PrefilledProtocol :: putProjectAnalysisAnswer :: onMutate");
            if (DEBUG) console.log(mutationData);

            const [_key, { projectId, answerId, updatedAnswer }] = mutationData.queryKey;

            // Cancel pending queries to prevent sync issues
            await queryClient.cancelQueries([queryKey, { projectId }]);

            if (DEBUG) console.log("queryKey", [queryKey, { projectId }]);

            const previousData = queryClient.getQueryData([queryKey, { projectId }]);
            if (DEBUG) console.log("PrefilledProtocol :: putProjectAnalysisAnswer :: onMutate :: previousData")
            if (DEBUG) console.log(previousData);

            let updated = [
                ...previousData.result
            ];

            let updatedQuestionIndex = -1;

            let updatedQuestion = updated
                .find((analysis, index) => {
                    if (analysis.answer.id === answerId) {
                        updatedQuestionIndex = index;
                        return true;
                    }
                });

            if (updatedQuestion) {
                if (DEBUG) console.log("PrefilledProtocol :: putProjectAnalysisAnswer :: onMutate :: updatedQuestion")
                if (DEBUG) console.log(updatedQuestion);

                updated[updatedQuestionIndex] = {
                    ...updatedQuestion,
                    answer: {
                        ...updatedQuestion.answer,
                        ...updatedAnswer
                    }
                }
            }

            if (DEBUG) console.log("PrefilledProtocol :: putProjectAnalysisAnswer :: onMutate :: updated");
            if (DEBUG) console.log(updated);

            queryClient.setQueryData([queryKey, { projectId }], {
                queryKey: [queryKey, { projectId }],
                result: updated
            });

            return { previousData };
        },
        // If the mutation fails, roll back to the previous data
        onError: (error, newData, context) => {
            if (DEBUG) console.log("PrefilledProtocol :: putProjectAnalysisAnswer :: ERROR")
            if (DEBUG) console.log(error);
            queryClient.setQueryData([queryKey, { projectId }], context.previousData);
        },
        // After the mutation succeeds, invalidate the query to re-fetch the data
        onSettled: () => {
            queryClient.invalidateQueries([queryKey, { projectId }]);
        },
        onSuccess: (result) => {
            if (DEBUG) console.log("PrefilledProtocol :: putProjectAnalysisAnswer :: onSuccess")
            if (DEBUG) console.log(result);
        }
    });

    useEffect(() => {
        if (!projectId) {
            if (selectedProject && selectedProject.id) {
                if (DEBUG) console.log("PrefilledProtocol :: Redirecting to project", selectedProject.id);
                navigate(`${RoutePaths.docai.protocol.root}/${selectedProject.id}`);
            } else {
                if (DEBUG) console.log("PrefilledProtocol :: Redirecting to projects");
                navigate(RoutePaths.docai.projects.root);
            }
        } else if (!selectedProject || selectedProject.id !== projectId) {
            if (DEBUG) console.log("PrefilledProtocol :: Setting active project", projectId);
            dispatch({ type: Actions.setActiveProject, payload: { id: projectId } });
        }
    }, [projectId]);

    const mutateAnswerStatus = (answerId, status) => {
        answerStatusMutation.mutate({
            queryKey: [
                Math.random(),
                {
                    projectId,
                    answerId,
                    updatedAnswer: {
                        accepted: status
                    },
                }
            ]
        });
    };

    const handleReject = (answerId) => {
        mutateAnswerStatus(answerId, false);
    };

    const handleApprove = (answerId) => {
        mutateAnswerStatus(answerId, true);
    };

    const handleReset = (answerId) => {
        mutateAnswerStatus(answerId, null);
    };

    if (isLoading) {
        return (
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}>
                <CircularProgress />
            </div>
        );
    } else if (error) {
        // TODO: BETTER ERROR HANDLING
        return <div>Error: {error.message}</div>
    } else if (!data || !data.result || !data.result.length === 0) {
        // TODO: BETTER ERROR HANDLING
        return <div>Broken data</div>
    } else {
        const projectAnalysisQuestion = data.result[selectedTab];
        const chatTab = data.result.length;
        const generateTab = data.result.length + 1;

        return (
            <div style={styles.root} key={projectId}>
                <Box style={{
                    marginRight: "auto",
                    marginLeft: "auto",
                    width: "90%",
                    height: "100%"
                }}>
                    {
                        selectedProject && selectedProject.name &&
                        <div style={{ height: "calc(64px)" }}>
                            <Stack direction="row" style={{ justifyContent: "space-between", alignItems: "flex-start" }}>
                                <Typography variant="h2" style={{ textAlign: "left" }} mt={-1} >{selectedProject.name}</Typography>
                                {
                                    selectedProject && selectedProject.description &&
                                    <Typography variant="body1" style={{ textAlign: "left", color: "rgba(0, 0, 0, 0.6)" }} mt={2} ml={"3px"}>{selectedProject.description}</Typography>
                                }
                            </Stack>
                        </div>
                    }
                    <div style={{ height: "calc(100% - 64px)" }}>
                        <Stack direction="row" spacing={10} style={styles.tabs} >
                            <TabPanel {...{
                                projectAnalysis: data.result,
                                selectedTab,
                                setSelectedTab,
                                resetChat: () => {
                                    if (DEBUG) console.log("resetChat");
                                    setResetChat(resetChat + 1);
                                }
                            }} />
                            {
                                selectedTab < chatTab &&
                                <Box style={{ ...styles.chatContainer, textAlign: "left" }}>
                                    <Stack spacing={4}>
                                        <Typography variant="h4">
                                            {projectAnalysisQuestion.question.description}?
                                        </Typography>
                                        {
                                            (projectAnalysisQuestion.answer &&
                                                projectAnalysisQuestion.answer.status === ANSWER_STATUS.success) &&
                                            <>
                                                <AnalysisAnswer {...{ projectAnalysisQuestion }} />
                                                <AnalysisEvidence {...{ projectAnalysisQuestion }} />
                                                <AnalysisConflicts {...{ projectAnalysisQuestion }} />
                                                <Stack direction="row" spacing={8} >
                                                    {
                                                        projectAnalysisQuestion.answer.accepted === null &&
                                                        <Button
                                                            onClick={
                                                                () => {
                                                                    handleApprove(projectAnalysisQuestion.answer.id)
                                                                }

                                                            }
                                                            disabled={answerStatusMutation.isPending}
                                                            variant="contained">
                                                            <FormattedMessage
                                                                id="prefilledProtocol.analysis.button.accept" />
                                                        </Button>
                                                    }
                                                    {
                                                        projectAnalysisQuestion.answer.accepted === null &&
                                                        <Button
                                                            onClick={
                                                                () => {
                                                                    handleReject(projectAnalysisQuestion.answer.id)
                                                                }
                                                            }
                                                            disabled={answerStatusMutation.isPending}>
                                                            <FormattedMessage
                                                                id="prefilledProtocol.analysis.button.deny" />
                                                        </Button>
                                                    }
                                                    {
                                                        projectAnalysisQuestion.answer.accepted !== null &&
                                                        <Button
                                                            onClick={
                                                                () => {
                                                                    handleReset(projectAnalysisQuestion.answer.id)
                                                                }
                                                            }
                                                            disabled={answerStatusMutation.isPending}>Palauta</Button>
                                                    }
                                                </Stack>
                                            </>
                                        }
                                        {
                                            (projectAnalysisQuestion.answer &&
                                                projectAnalysisQuestion.answer.status === ANSWER_STATUS.processing) &&
                                            <>
                                                <Typography variant="body1">
                                                    <FormattedMessage id="prefilledProtocol.analysis.status.processing" />
                                                </Typography>
                                                <LinearProgress />
                                            </>

                                        }
                                        {
                                            (projectAnalysisQuestion.answer &&
                                                projectAnalysisQuestion.answer.status === ANSWER_STATUS.error) &&
                                            <>
                                                <Typography variant="body1">
                                                    <FormattedMessage id="prefilledProtocol.analysis.status.error" />
                                                </Typography>
                                            </>
                                        }
                                    </Stack>
                                </Box>
                            }
                            {
                                selectedTab === chatTab &&
                                <Box style={{ ...styles.chatContainer, textAlign: "left" }}>
                                    <ProjectChat key={resetChat} />
                                </Box>
                            }
                            {
                                selectedTab === generateTab &&
                                <Box style={{ ...styles.chatContainer, textAlign: "left" }}>
                                    <DocumentGeneration />
                                </Box>
                            }
                        </Stack>
                    </div>
                </Box>

            </div >
        );
    }
}

export default PrefilledProtocol;

/*

                            <Typography variant="h6">
                                Kysy aineistosta
                            </Typography>
                            <Input placeholder="Kirjoita kysymys" />
                            */