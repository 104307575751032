import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

const DEBUG = true;

const ChatSessions = ({ sessions, setSelectedSessionId }) => {
    const intl = useIntl();
    if (!sessions) {
        console.log("ChatSessions :: sessions is null");
        return null;
    }

    return (
        <>
            <List style={{ overflow: "auto" }}>
                {
                    sessions.length === 0 &&
                    <Typography variant="body1">
                        <FormattedMessage
                            id="prefilledProtocol.chat.emptyHistory" />
                    </Typography>
                }
                {
                    sessions.map((session, index) => {
                        if (DEBUG) console.log("ChatSessions :: session", session);

                        let secondaryText = "";

                        if (!session.chat_messages || session.chat_messages.length === 0) {
                            secondaryText = intl.formatMessage({ id: "prefilledProtocol.chat.emptySession" });
                        } else {
                            secondaryText = session.chat_messages[0].message_content.length > 100
                                ? `${session.chat_messages[0].message_content.substring(0, 97)}...`
                                : session.chat_messages[0].message_content;
                        }

                        return < ListItem key={index} >
                            <ListItemText
                                style={{ cursor: "pointer" }}
                                onClick={
                                    () => {
                                        if (setSelectedSessionId) {
                                            setSelectedSessionId(session.id);
                                        }
                                    }}
                                primary={`${new Date(session.created_at).toLocaleString()}`}
                                secondary={secondaryText}
                            />
                        </ListItem>
                    })

                }
            </List>
        </>
    );
};

export default ChatSessions;