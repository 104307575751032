import { ENDPOINTS, createEndpointUrl } from './ApiConstants';
import {
    InteractionRequiredAuthError
} from '@azure/msal-browser';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const DEBUG = true;

export default async function getAccessToken(
    msalInstance,
    scopes = [`api://${process.env.REACT_APP_BACKEND_APP_ID}/docai.use`],
) {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        // Handle signed-out state appropriately
        console.log('No active account found');
        return null;
    }

    try {
        const silentResult = await msalInstance.acquireTokenSilent({
            scopes: scopes,
            account: account,
        });
        return silentResult.accessToken;
    } catch (error) {
        console.error(error);
        if (error instanceof InteractionRequiredAuthError) {
            // Fallback to interactive method
            const interactiveResult = await msalInstance.acquireTokenPopup({
                scopes: scopes,
            });
            return interactiveResult.accessToken;
        } else {
            // Handle other errors appropriately
            return null;
        }
    }
}


// Helper function to construct a GET URL with query parameters, if needed.
function toQueryString(obj) {
    const queryString = Object.keys(obj)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
        .join('&');
    return queryString;
}

// EXAMPLE GET IMPLEMENTATION
export function exampleGet({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: exampleGet");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, { parameters }] = queryKey;

        try {
            const url = `${backendUrl}${ENDPOINTS.exampleGet}?${toQueryString(parameters)}`;

            if (DEBUG) console.log("Api :: exampleGet :: GET :: " + url);
            const response = await fetch(url, {
                "method": 'GET',
                headers: {
                    "Content-Type": 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                }
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: exampleGet :: GET :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: exampleGet :: GET :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('Api :: exampleGet ::  request error:', error);
            throw error;
        }
    }
}

// EXAMPLE POST IMPLEMENTATION
export function examplePost({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: examplePost");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, { parameters }] = queryKey;

        try {
            const url = `${backendUrl}${ENDPOINTS.examplePost}`;

            const jsonBody = {
                parameters
            };

            if (DEBUG) console.log("Api :: exampleGet :: POST :: " + url);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
                body: JSON.stringify(jsonBody),
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: exampleGet :: POST :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: exampleGet :: POST :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}

// GET PROJECTS IMPLEMENTATION
export function getProjects({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: getProjects");
        const accessToken = await getAccessToken(msalInstance);

        const [_key] = queryKey;

        try {
            const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.getProjects)}`;

            if (DEBUG) console.log("Api :: getProjects :: GET :: " + url);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: getProjects :: GET :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: getProjects :: GET :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}

// GET PROJECT IMPLEMENTATION
export function getProject({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: getProject");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, { projectId }] = queryKey;

        try {
            const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.getProject, { projectId })}`;

            if (DEBUG) console.log("Api :: getProject :: GET :: " + url);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: getProject :: GET :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: getProject :: GET :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}

// PUT PROJECT IMPLEMENTATION
export function putProject({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: putProject");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, {
            projectId,
            updatedProject }] = queryKey;

        try {
            const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.putProject, { projectId })}`;

            if (DEBUG) console.log("Api :: putProject :: PUT :: " + url);
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
                body: JSON.stringify(updatedProject)
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: putProject :: PUT :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: putProject :: PUT :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}

// GET PROJECT ANALYSIS IMPLEMENTATION
export function getProjectAnalysis({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: getProjectAnalysis");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, { projectId }] = queryKey;

        try {
            const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.getProjectAnalysis, { projectId })}`;

            if (DEBUG) console.log("Api :: getProjectAnalysis :: GET :: " + url);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: getProjectAnalysis :: GET :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: getProjectAnalysis :: GET :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}

// POST PROJECT ANALYSIS IMPLEMENTATION
export function postProjectAnalysis({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: postProjectAnalysis");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, { projectId }] = queryKey;
        const analysisData = {};

        try {
            const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.postProjectAnalysis, { projectId })}`;

            if (DEBUG) console.log("Api :: postProjectAnalysis :: POST :: " + url);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
                body: JSON.stringify(analysisData)
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: postProjectAnalysis :: POST :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: postProjectAnalysis :: POST :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}

// PUT PROJECT ANALYSIS ANSWER IMPLEMENTATION
export function putProjectAnalysisAnswer({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: putProjectAnalysisAnswer");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, { projectId, answerId, updatedAnswer }] = queryKey;

        try {
            const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.putProjectAnalysisAnswer, { projectId, answerId })}`;

            if (DEBUG) console.log("Api :: putProjectAnalysisAnswer :: PUT :: " + url);
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
                body: JSON.stringify(updatedAnswer)
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: putProjectAnalysisAnswer :: PUT :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: putProjectAnalysisAnswer :: PUT :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}

// GET CHAT SESSIONS IMPLEMENTATION
export function getChatSessions({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: getChatSessions");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, { projectId }] = queryKey;

        try {
            const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.getChatSessions, { projectId })}`;

            if (DEBUG) console.log("Api :: getChatSessions :: GET :: " + url);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: getChatSessions :: GET :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: getChatSessions :: GET :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}

// GET CHAT SESSION IMPLEMENTATION
export function getChatSession({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: getChatSession");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, { sessionId, projectId }] = queryKey;

        try {
            const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.getChatSession, { sessionId, projectId })}`;

            if (DEBUG) console.log("Api :: getChatSession :: GET :: " + url);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: getChatSession :: GET :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: getChatMessages :: GET :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}

// POST CHAT SESSION IMPLEMENTATION
export function postChatSession({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: postChatSession");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, { projectId }] = queryKey;

        try {
            const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.postChatSession, { projectId })}`;

            if (DEBUG) console.log("Api :: postChatSession :: POST :: " + url);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
                body: JSON.stringify({})
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: postChatSession :: POST :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: postChatSession :: POST :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}

// POST CHAT MESSAGE IMPLEMENTATION
export function postChatMessage({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: postChatMessage");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, { sessionId, messageContent }] = queryKey;
        const jsonBody = {
            chat_session_id: sessionId,
            message_content: messageContent
        };

        try {
            const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.postChatMessage)}`;

            if (DEBUG) console.log("Api :: postChatMessage :: POST :: " + url);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
                body: JSON.stringify(jsonBody)
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: postChatMessage :: POST :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: postChatMessage :: POST :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}

// POST GENERATE DOCUMENT IMPLEMENTATION
export function postGenerateDocument({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: postGenerateDocument");
        const accessToken = await getAccessToken(msalInstance);

        const [_key, { projectId }] = queryKey;

        try {
            const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.postGenerateDocument, { projectId })}`;

            if (DEBUG) console.log("Api :: postGenerateDocument :: POST :: " + url);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                    // @TEMPLATE - Add any additional, like auth, headers here.
                    // "x-api-key": apiKey,
                },
                body: JSON.stringify({})
            });

            if (!response.ok) {
                if (DEBUG) console.log("Api :: postGenerateDocument :: POST :: ERROR");
                throw new Error(`API request failed with status ${response.status}`);
            }

            const result = await response.json();

            if (DEBUG) console.log("Api :: postGenerateDocument :: POST :: Success");
            return { queryKey: _key, result };
        } catch (error) {
            console.error('API request error:', error);
            throw error;
        }
    }
}