import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppStateProvider } from './AppState';
import { msalConfig } from "./authConfig.js";
import { PublicClientApplication, EventType, EventMessage } from "@azure/msal-browser";

/**
* MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
* For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
*/
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {

    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    if (!msalInstance.getActiveAccount() && accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }

        if (event.eventType === EventType.LOGIN_FAILURE) {
            // Check for forgot password error
            // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes

            console.log("login failure");

            if (event.error && event.error.errorMessage) {
                console.log(event.error.errorMessage)
            }
        }
    });

    msalInstance.handleRedirectPromise().then((response) => {
        if (response) {
            console.log('Login successful:', response.account);
        } else {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length > 0) {
                console.log('Already logged in:', accounts[0]);
            }
        }

        if (!msalInstance.getActiveAccount()) {
            msalInstance.loginRedirect();
        }
    }).catch((error) => {
        console.error('Login error:', error);
    });

    console.log(msalInstance.getAllAccounts());

    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <AppStateProvider>
                <App msalInstance={msalInstance} />
            </AppStateProvider>
        </React.StrictMode>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
})