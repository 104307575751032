import { createTheme } from '@mui/material';

const theme = {
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            light: '#ffffff',
            main: '#0693e3',
            dark: '#163073',
            // contrastText: '#ffffff',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#9b51e0',
            // dark: will be calculated from palette.secondary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        // Provide every color token (light, main, dark, and contrastText) when using
        // custom colors for props in Material UI's components.
        // Then you will be able to use it like this: `<Button color="custom">`
        // (For TypeScript, you need to add module augmentation for the `custom` value)
        custom: {
            main: '#00d084',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    backgroundColor: "white",
                    color: "#0693e3"
                }
            }
        }
    }
}

const ParmacoTheme = {
    light: createTheme(theme),
    dark: createTheme({
        palette: { ...theme.palette, mode: 'dark' }
    })
}

export default ParmacoTheme;