import './App.css';
import Router from './routes/Router';
import { RouterProvider } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { IntlProvider } from 'react-intl';
import { useAppState } from './AppState';
import messages from './messages/messages';
import { MsalProvider, useMsal } from '@azure/msal-react';

const queryClient = new QueryClient();

function App({ msalInstance }) {
    const { state } = useAppState();

    return (
        <MsalProvider instance={msalInstance}>
            <QueryClientProvider client={queryClient}>
                <IntlProvider locale={state.locale} messages={messages[state.locale]}>
                    <div className="App">
                        <RouterProvider router={Router}>
                        </RouterProvider>
                    </div>
                </IntlProvider>
            </QueryClientProvider>
        </MsalProvider>

    );
}

export default App;
