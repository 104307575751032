import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Input
} from '@mui/material';
import { useIntl } from 'react-intl';

const ImportProjectDialog = ({
    projectId,
    open,
    description,
    setDescription,
    handleAccept,
    handleDecline }) => {
    const intl = useIntl();

    const header = intl.formatMessage({ id: "project.import.header" });
    const descriptionInfo = intl.formatMessage({ id: "project.import.description" });
    const accept = intl.formatMessage({ id: "project.import.yes" });
    const decline = intl.formatMessage({ id: "project.import.no" });

    return <Dialog
        open={open}
        onClose={handleDecline}
    >
        <DialogTitle>
            {header} {projectId}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {descriptionInfo}
            </DialogContentText>
            <Input
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={intl.formatMessage({ id: "project.import.descriptionPlaceholder" })}
                fullWidth />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDecline}>
                {decline}
            </Button>
            <Button onClick={handleAccept} autoFocus>
                {accept}
            </Button>
        </DialogActions>
    </Dialog>
}

export default ImportProjectDialog;