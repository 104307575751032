import exampleData from '../../data/example.json';
import testData from '../../data/testData.json';
import { ENDPOINTS, createEndpointUrl } from '../ApiConstants';

const DEBUG = true;
const backendUrl = "backendUrl/";

// EXAMPLE GET MOCK IMPLEMENTATION
export async function exampleGet({ queryKey }) {
    if (DEBUG) console.log("Api :: MOCK :: exampleGet");

    const [_key, { parameters }] = queryKey;

    if (DEBUG) console.log(parameters);

    await new Promise(resolve => setTimeout(resolve, 2000));

    const result = {
        data: exampleData.get,
        info: `parameters: ${JSON.stringify(parameters)}`
    };

    return { queryKey: _key, result };
}

// EXAMPLE POST MOCK IMPLEMENTATION
export async function examplePost({ queryKey }) {
    if (DEBUG) console.log("Api :: MOCK :: examplePost");

    const [_key, { parameters }] = queryKey;

    if (DEBUG) console.log(parameters);

    await new Promise(resolve => setTimeout(resolve, 2000));

    const result = {
        data: exampleData.post,
        info: `parameters: ${JSON.stringify(parameters)}`
    };

    return { queryKey: _key, result };
}

export function getProjects({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: MOCK :: getProjects");
        console.log("msalInstance", msalInstance);

        const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.getProjects)}`;
        console.log("url", url);

        const [_key] = queryKey;

        await new Promise(resolve => setTimeout(resolve, 2000));

        const result = testData.getProjects;

        return { queryKey: _key, result };
    }
}

export function putProject({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: MOCK :: putProject");

        const [_key, {
            projectId,
            updatedProject }] = queryKey;

        const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.putProject, { projectId })}`;

        if (DEBUG) console.log("Api :: MOCK :: putProject :: PUT :: " + url);
        if (DEBUG) console.log("Api :: MOCK :: putProject :: updatedProject", updatedProject);

        return { queryKey: _key, result: {} };
    }
}

export function getProjectAnalysis({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: MOCK :: getProject");
        console.log("msalInstance", msalInstance);
        const [_key, { projectId }] = queryKey;

        const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.getProjectAnalysis, { projectId })}`;
        console.log("url", url);

        await new Promise(resolve => setTimeout(resolve, 2000));

        const result = testData.getProjectAnalysis;

        return { queryKey: _key, result };
    }
}

// PUT PROJECT ANALYSIS ANSWER IMPLEMENTATION
export function putProjectAnalysisAnswer({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: MOCK :: putProjectAnalysisAnswer");

        const [_key, { projectId, answerId, updatedAnswer }] = queryKey;

        const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.putProjectAnalysisAnswer, { projectId, answerId })}`;
        console.log("url", url);

        await new Promise(resolve => setTimeout(resolve, 2000));

        let resultData = {
            ...testData
                .getProjectAnalysis
                .find((analysis) => analysis.answer.id === answerId)
        };

        resultData.answer = {
            ...resultData.answer,
            ...updatedAnswer
        };

        const result = {
            data: resultData,
            info: `parameters: ${JSON.stringify({ projectId, answerId, updatedAnswer })}`
        };

        return { queryKey: _key, result };
    }
}


// GET CHAT SESSIONS IMPLEMENTATION
export function getChatSessions({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: MOCK :: getChatSessions");

        const [_key, { projectId }] = queryKey;

        const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.getChatSessions, { projectId })}`;

        if (DEBUG) console.log("url", url);

        await new Promise(resolve => setTimeout(resolve, 2000));

        const result = testData.getChatSessions;

        return { queryKey: _key, result };
    }
}

// GET CHAT SESSION IMPLEMENTATION
export function getChatSession({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: MOCK :: getChatSession");

        const [_key, { sessionId }] = queryKey;
        if (DEBUG) console.log(sessionId);

        const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.getChatSession, { sessionId })}`;

        if (DEBUG) console.log("url", url);

        await new Promise(resolve => setTimeout(resolve, 2000));

        const result = testData.getChatMessages;

        return { queryKey: _key, result };
    }
}

// POST CHAT SESSION IMPLEMENTATION
export function postChatSession({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: MOCK :: postChatSession");

        const [_key, { projectId }] = queryKey;

        const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.postChatSession, { projectId })}`;

        if (DEBUG) console.log("url", url);

        await new Promise(resolve => setTimeout(resolve, 2000));

        const result = testData.getChatSessions[0];
        return { queryKey: _key, result };
    }
}

// POST CHAT MESSAGE IMPLEMENTATION
export function postChatMessage({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: MOCK :: postChatMessage");

        const [_key, { sessionId, messageContent }] = queryKey;
        const jsonBody = {
            chat_session_id: sessionId,
            message_content: messageContent
        };

        const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.postChatMessage)}`;

        if (DEBUG) console.log("url", url);
        if (DEBUG) console.log("jsonBody", jsonBody);

        await new Promise(resolve => setTimeout(resolve, 2000));

        return {
            queryKey: _key, result: {
                data: {
                    chat_session_id: sessionId,
                    message_content: "Dummy bot msg",
                    role: 'system',
                    created: Date.now()
                }
            }
        }
    };
}

// POST PROJECT ANALYSIS IMPLEMENTATION
export function postProjectAnalysis({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: MOCK :: postProjectAnalysis");

        const [_key, { projectId }] = queryKey;

        const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.postProjectAnalysis, { projectId })}`;

        if (DEBUG) console.log("url", url);

        await new Promise(resolve => setTimeout(resolve, 2000));

        const result = {};

        return { queryKey: _key, result };
    }
}

// POST CHAT SESSION IMPLEMENTATION
export function postGenerateDocument({ msalInstance }) {
    return async ({ queryKey }) => {
        if (DEBUG) console.log("Api :: MOCK :: postGenerateDocument");

        const [_key, { projectId }] = queryKey;

        const url = `${backendUrl}${createEndpointUrl(ENDPOINTS.postGenerateDocument, { projectId })}`;

        if (DEBUG) console.log("url", url);

        await new Promise(resolve => setTimeout(resolve, 2000));

        const result = {
            file: {
                name: "poytakirja-ai.pdf",
                url: "https://sharepoint/1234/poytakirja-ai.pdf"
            }
        };

        return { queryKey: _key, result };
    }
}