
// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_APP_ID,
        authority:
            `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
    },
    cache: {
        cacheLocation: 'localStorage', // Configure local storage
        storeAuthStateInCookie: true,  // Set to true to help with issues on certain browsers
    }
};
