import React, { useEffect } from 'react';
import Link from '@mui/material/Link';
import { useIntl } from 'react-intl';
import { useAppState } from './../AppState';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import {
    useLocation,
    Link as RouterLink,
} from 'react-router-dom';
import RoutePaths from './RoutePaths';


function LinkRouter(props) {
    return <Link {...props} component={RouterLink} />;
}

const Navi = () => {
    const location = useLocation();
    const { state } = useAppState();
    const locale = state.locale;
    const intl = useIntl();
    const [pathnames, setPathnames] = React.useState({});

    useEffect(() => {
        let pathnames = {};

        pathnames[RoutePaths.root] = intl.formatMessage({ id: "routes.home" });
        pathnames[RoutePaths.docai.projects.root] = intl.formatMessage({ id: "routes.projects" });
        pathnames[RoutePaths.docai.protocol.root] = intl.formatMessage({ id: "routes.protocol" });

        setPathnames(pathnames);
    }, [locale]);

    return (
        <Breadcrumbs color="silver" aria-label="breadcrumb">
            {
                Object.keys(pathnames).map((value, index) => {
                    const to = value;

                    if (location.pathname === to) {
                        return <Typography color="#0693e3" key={to}>
                            {pathnames[value]}
                        </Typography>
                    } else if (location.pathname.includes(to) && to === RoutePaths.docai.protocol.root) {
                        return <Typography color="#0693e3" key={to}>
                            {pathnames[value]}
                        </Typography>
                    } else {
                        return <LinkRouter
                            underline="hover"
                            color="#163073"
                            to={to}
                            key={to}>
                            {pathnames[value]}
                        </LinkRouter>
                    }
                })}
        </Breadcrumbs>
    );
}

export default Navi;