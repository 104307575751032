import EN from './messages.en.json';
import DE from './messages.de.json';
import FI from './messages.fi.json';
import SE from './messages.se.json';

let userLocale = navigator ?
    navigator.language || navigator.userLanguage
    : null;

if (userLocale !== "de-DE" ||
    userLocale !== "fi-FI" ||
    userLocale !== "en-US" ||
    userLocale !== "sv-SE") {
    userLocale = "fi-FI";
}

export const browserLocale = userLocale.split("-")[0];

export const locales = {
    en: "en",
    de: "de",
    fi: "fi",
    sv: "sv"
}

const messages = {
    [locales.en]: EN,
    [locales.de]: DE,
    [locales.fi]: FI,
    [locales.sv]: SE
};

export default messages;