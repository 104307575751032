import React from 'react';
import { TextField, IconButton, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

const ChatInput = ({ disabled, text, setText, placeholder, send, sending }) => {
    const handleSend = () => {
        if (!sending) {
            send(text);
        }
    };

    return (
        <div>
            <TextField
                disabled={disabled}
                size="small"
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder={placeholder}
                multiline
                fullWidth
                InputProps={{
                    endAdornment: (
                        <IconButton
                            disabled={disabled}
                            onClick={handleSend}>
                            {
                                sending ?
                                    <CircularProgress size={20} /> :
                                    <SendIcon />
                            }
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
};

export default ChatInput;