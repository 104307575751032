import {
    createBrowserRouter,
} from "react-router-dom";
import AppPage from "../AppPage";
import RoutePaths from "./RoutePaths";
// @TEMPLATE: Import the necessary components and themes here.
import PrefilledProtocol from "../docai/prefilled-protocol/PrefilledProtocol";
import ProjectPage from "../docai/projects/ProjectPage";


// @TEMPLATE: Define the router path components here.
const Router = createBrowserRouter([
    {
        path: RoutePaths.root,
        element: <AppPage />,
    },
    {
        path: RoutePaths.docai.root,
        element: <AppPage />,
    },
    {
        path: `${RoutePaths.docai.projects.root}`,
        element: <AppPage>
            <ProjectPage />
        </AppPage>
    },
    {
        path: `${RoutePaths.docai.protocol.root}`,
        element: <AppPage>
            <PrefilledProtocol />
        </AppPage>
    },
    {
        path: `${RoutePaths.docai.protocol.project}`,
        element: <AppPage>
            <PrefilledProtocol />
        </AppPage>
    },
]);

export default Router;